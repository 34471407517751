import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { dynamicData } from '../../helpers/data';
import { notification } from 'antd';
import { useSelector } from 'react-redux';
import useGeneratePdfReport from '../../api/useGeneratePdfReport';
import ThankYouPageOne from './ThankYouPageOne';

export const ThankYouJc = () => {
    const isSchoolRegistration = useSelector( state => state.schoolRegistration.isSchoolRegistration )
    const navigate = useNavigate();
    const { client_name } = useParams();
    const formConfigRedux = useSelector( state => state.formConfiguration );

    const formConfig = formConfigRedux?.form_config;
    const clientid = formConfig?.client_id

    let thankYouPage =
        formConfig.sign_up_content?.thank_you_page ||
        dynamicData[clientid]?.sign_up_content?.thank_you_page ||
        dynamicData.default?.sign_up_content?.thank_you_page;

    let heading = thankYouPage?.heading || "";
    let sub_heading_one = thankYouPage?.sub_heading_one || "";
    let sub_heading_two = thankYouPage?.sub_heading_two || "";
    let sub_heading_three = thankYouPage?.sub_heading_three || "";

    const handleTest = () => {
        window.open( `/${ client_name }/test/signup`, '_blank' );
    };

    return (
        <div className="p-8 bg-white shadow-lg rounded-lg max-w-xl mx-auto text-center">
            <h1 className="text-2xl font-bold mb-4">{ heading }</h1>
            <p className="mb-6">{ sub_heading_one }</p>
            <h1 className="text-2xl font-bold mb-4">{ sub_heading_two }</h1>
            <p className="mb-8">{ sub_heading_three }</p>
            <button className="bg-blue-500 text-white text-center rounded py-2 px-4 mb-2 hover:bg-blue-600" onClick={ handleTest }>
                Take me to the test
            </button>
        </div>
    );
};

export const ThankYouJcTest = () => {

    const student_name = localStorage.getItem( 'studentName' ) || "abc";
    const student_email = localStorage.getItem( 'user_email' ) || "abc@gmail.com";
    const [reportLink, setReportLink] = useState( null );
    const formConfigRedux = useSelector( state => state.formConfiguration );
    const [studentRollNumber, setStudentRollNumber] = useState( null );

    const formConfig = formConfigRedux?.form_config;
    const formid = formConfig?.form_id;

    const { data: pdfReport, isLoading: pdfReportGenerating, generatePdfReport } = useGeneratePdfReport();

    const handleBooking = () => {
        window.open( `https://bookings.strideahead.io/team-stride/mentorship-session/?name=${ student_name }&email=${ student_email }`, '_blank' );
    }

    useEffect( () => {
        if ( studentRollNumber ) {
            generatePdfReport( studentRollNumber, formid )
        }
    }, [studentRollNumber] )

    useEffect( () => {
        setStudentRollNumber( localStorage.getItem( 'student_roll_number' ) );
    }, [] )

    const handleDownloadReport = async () => {
        if ( pdfReportGenerating ) {
            notification.info( {
                message: 'PDF Report is on it\'s way',
            } );
        }
        if ( pdfReport && pdfReport.length > 0 ) {
            const downloadLink = document.createElement( "a" );
            downloadLink.style.display = "none";
            downloadLink.href = reportLink;

            // Append the anchor element to the document body
            document.body.appendChild( downloadLink );

            // Trigger a click event on the anchor to start the download
            downloadLink.click();

            // Remove the anchor from the document
            document.body.removeChild( downloadLink );
        }
    }

    useEffect( () => {
        if ( pdfReport && reportLink === null ) {
            setReportLink( pdfReport );
        }
    }, [pdfReport] )

    return (
        <>
            {
                <ThankYouPageOne
                    handleReport={ handleDownloadReport }
                    handlementorshipbooking={ handleBooking }
                />
            }
        </>
    );
};

