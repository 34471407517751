import React, { useState, useEffect, useRef } from 'react';
import LeftSideBar from '../LeftSideBar';
import { useDispatch, useSelector } from 'react-redux';
import useFetchStudentDataEmail from '../../api/FetchStudentDetailsEmail';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { notification, Spin, Input } from 'antd';
import useCreateStudentCode from '../../api/CreateStudentCode';
import useRequestOTPEmail from '../../api/RequestOtpEmail';
import useFetchClientConfig from '../../api/FetchClientConfig';
import useVerifyOTPEmail from '../../api/VerifyOtpEmail';
import { setClientConfiguration } from '../../redux/student/studentSlice';
import 'react-phone-number-input/style.css';
import PhoneInput, { isValidPhoneNumber, formatPhoneNumber } from 'react-phone-number-input';

const SigninCouponCode = ( { currentComponent, currentComponentNext } ) => {
    const [selectedTab, setSelectedTab] = useState( 'signup' );
    const [email, setEmail] = useState( '' );
    const [name, setName] = useState( '' );
    const [phoneNumber, setPhoneNumber] = useState( '' );
    const [grade, setGrade] = useState( '' );
    const [dob, setDob] = useState( '' );
    const [couponCode, setCouponCode] = useState( '' );
    const [otpVisible, setOtpVisible] = useState( false );
    const [otp, setOtp] = useState( ['', '', '', ''] );
    const inputRefs = useRef( [] );

    const [isEmailValid, setIsEmailValid] = useState( true );
    const [isNameValid, setIsNameValid] = useState( true );
    const [isPhoneNumberValid, setIsPhoneNumberValid] = useState( true );
    const [phoneError, setPhoneError] = useState( '' );

    const [emailEditable, setEmailEditable] = useState( true );

    const { client_name } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const clientConfig = useSelector( state => state.clientConfiguration );

    const testId = localStorage.getItem( 'testId' );
    const clientConfiguration = sessionStorage.getItem( 'clientConfiguration' );
    const userAssessmentResponseID = localStorage.getItem( 'userAssessmentResponseID' );

    const { response: studentData, isLoading: studentDataLoading, FetchStudentDataEmail } = useFetchStudentDataEmail();
    const { createStudentCode, response: createStudentResponse, isLoading: creatingStudent } = useCreateStudentCode();
    const { emailData, isRequestingOTP: emailVerifying, requestOTP } = useRequestOTPEmail();
    const { response: clientConfigData, isLoading, fetchClientConfig } = useFetchClientConfig();
    const { data: emailVerified, error: emailNotVerified, isLoading: verifyingOTP, verifyOTP } = useVerifyOTPEmail();

    const handleSendOTP = () => {
        FetchStudentDataEmail( email, client_name );
    };

    const handleVerifyOTP = () => {
        verifyOTP( email, otp.join( '' ) )
    }

    useEffect( () => {
        if ( emailVerified && emailVerified?.success ) {
            if ( testId ) {
                navigate( `/${ client_name }/test/${ testId }/?user_name=${ studentData?.name }&user_email=${ studentData?.email }` );
            } else {
                navigate( `/${ client_name }/test/selection/?user_email=${ studentData?.email }&user_name=${ studentData?.name }&user_id=${ studentData?.student_code }` );
            }
        } else if ( emailNotVerified?.response?.status === 400 ) {
            notification.error( {
                message: 'Incorrect OTP. Please try again',
            } );
        }
    }, [emailVerified, emailNotVerified] )

    useEffect( () => {
        if ( client_name && !clientConfiguration && !clientConfigData ) {
            fetchClientConfig( client_name );
        }
    }, [] );

    const handleOtpChange = ( e, index ) => {
        const { value } = e.target;
        const newOtp = [...otp];

        if ( /^[0-9]$/.test( value ) ) {
            newOtp[index] = value;
            setOtp( newOtp );
            if ( index < otp.length - 1 ) {
                inputRefs.current[index + 1].focus();
            }
        } else if ( value === '' && e.nativeEvent.inputType === 'deleteContentBackward' ) {
            if ( index > 0 ) {
                inputRefs.current[index - 1].focus();
            }
            newOtp[index] = '';
            setOtp( newOtp );
        }
    };

    useEffect( () => {
        if ( emailData ) {
            notification.success( {
                message: 'OTP sent to the registered email id',
            } );
            setOtpVisible( true );
        }
    }, [emailData] )

    const url = window.location.href;

    useEffect( () => {
        if ( url.includes( 'signup' ) ) {
            setSelectedTab( 'signup' )
        } else if ( url.includes( 'signin' ) ) {
            setSelectedTab( 'signin' )
        } else {
            console.log( 'The URL does not contain "signup" or "signin"' );
        }
    }, [] );

    useEffect( () => {
        if ( clientConfigData && clientConfigData?.data ) {
            dispatch( setClientConfiguration( clientConfigData.data ) );
            sessionStorage.setItem( 'clientConfiguration', JSON.stringify( clientConfigData.data ) );

            const timeout = setTimeout( () => {
                sessionStorage.removeItem( 'clientConfiguration' );
                fetchClientConfig( client_name );
            }, 10 * 60 * 1000 );
            return () => clearTimeout( timeout );
        }
    }, [clientConfigData, client_name] );

    //navigate( `/${ client_name }/test/selection/?user_email=${ studentData?.email }&user_name=${ studentData?.name }&user_id=${ studentData?.student_code }` );

    useEffect( () => {
        if ( studentData && studentData?.student_code ) {
            setEmailEditable( false );
            requestOTP( email, client_name );
        } else if ( studentData && studentData?.status_code === 404 ) {
            notification.error( {
                message: 'Email not found. Please sign up first.',
            } );
        }
    }, [studentData] );

    const handleSignup = () => {
        let isValid = true;

        if ( !name ) {
            setIsNameValid( false );
            isValid = false;
        } else {
            setIsNameValid( true );
        }

        if ( !email ) {
            setIsEmailValid( false );
            isValid = false;
        } else {
            setIsEmailValid( true );
        }

        if ( !phoneNumber ) {
            setIsPhoneNumberValid( false );
            setPhoneError( '*required' );
            isValid = false;
        } else if ( !isValidPhoneNumber( phoneNumber ) ) {
            setIsPhoneNumberValid( false );
            setPhoneError( 'Incorrect phone number' );
            isValid = false;
        } else {
            setIsPhoneNumberValid( true );
        }

        if ( isValid ) {
            localStorage.setItem( 'phone_number', phoneNumber );
            localStorage.setItem( 'studentName', name );
            localStorage.setItem( 'user_email', email );
            createStudentCode( name, grade, email, client_name, userAssessmentResponseID, dob, clientConfig?.client_id, phoneNumber );
        } else {
            notification.error( {
                message: 'Please fill in the required fields'
            } );
        }
    };


    useEffect( () => {
        if ( createStudentResponse ) {
            const studentRollNumber = createStudentResponse?.student_code;
            localStorage.setItem( 'student_roll_number', studentRollNumber );
            notification.success( {
                message: "Sign up successful"
            } )
            if ( testId && currentComponent !== "sign_up_view" ) {
                navigate( `/${ client_name }/test/${ testId }/?user_name=${ createStudentResponse?.name }&user_email=${ createStudentResponse?.email }` );
            } else if ( !testId && currentComponent !== "sign_up_view" ) {
                navigate( `/${ client_name }/test/selection/?user_email=${ createStudentResponse?.email }&user_name=${ createStudentResponse?.name }&user_id=${ createStudentResponse?.student_code }` );
            } else if ( currentComponent === "sign_up_view" ) {
                currentComponentNext();
            }
        }
    }, [createStudentResponse] )



    return (
        <div className='h-full'>
            {
                ( studentDataLoading || isLoading || creatingStudent || emailVerifying || verifyingOTP ) &&
                <div className="overlay">
                    <Spin size="large" />
                </div>
            }
            <div className="flex flex-col md:flex-row h-full items-center">
                {
                    ( url.includes( 'signup' ) || url.includes( 'signin' ) ) &&
                    <LeftSideBar />
                }
                <div className="flex flex-col sm:mt-[1rem] lg:mt-0 w-full">
                    <div className="w-full flex flex-col items-center justify-center px-3 py-8 mx-auto">
                        { selectedTab === 'signin' && (
                            <div className="lg:w-1/2 sm:w-full bg-white rounded-lg shadow">
                                <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                                    <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl">
                                        Hi, Let's go first with your details
                                    </h1>
                                    <div className="space-y-4 md:space-y-6">
                                        <div>
                                            <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900">Your email</label>
                                            <input
                                                type="text"
                                                value={ email }
                                                onChange={ ( e ) => setEmail( e.target.value ) }
                                                style={ { resize: "none" } }
                                                className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                                placeholder="name@company.com"
                                                required=""
                                                disabled={ !emailEditable }
                                            />
                                        </div>
                                        { otpVisible && (
                                            <div className="flex justify-center mb-4 transition-transform duration-500 transform translate-y-4">
                                                { otp.map( ( value, index ) => (
                                                    <Input
                                                        key={ index }
                                                        type="text"
                                                        maxLength={ 1 }
                                                        value={ value }
                                                        onChange={ ( e ) => handleOtpChange( e, index ) }
                                                        ref={ el => inputRefs.current[index] = el }
                                                        className="w-12 h-12 mx-1 text-center border border-gray-300 rounded-lg"
                                                    />
                                                ) ) }
                                            </div>
                                        ) }
                                        {
                                            emailData ?
                                                <button
                                                    onClick={ handleVerifyOTP }
                                                    className="w-full text-white font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                                                    style={ {
                                                        backgroundColor: clientConfig.primary_color,
                                                    } }
                                                >
                                                    Verify OTP
                                                </button>
                                                :
                                                <button
                                                    onClick={ handleSendOTP }
                                                    className="w-full text-white font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                                                    style={ {
                                                        backgroundColor: clientConfig.primary_color,
                                                    } }
                                                >
                                                    Send OTP
                                                </button>
                                        }
                                    </div>
                                </div>
                            </div>
                        ) }
                        { selectedTab === 'signup' && (
                            <div className={ `sm:w-full bg-white rounded-lg shadow ${ url.includes( 'signup' ) || url.includes( 'signin' ) ? 'lg:w-3/4' : 'lg:w-full' }` }>
                                <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                                    <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl">
                                        Hi 👋🏻, Let's go first with your details
                                    </h1>
                                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                                        <div className="col-span-1">
                                            <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900">Your name* { !isNameValid &&
                                                <span className='ml-3 text-xs text-[#FF0000] font-light'>
                                                    *required
                                                </span> }
                                            </label>
                                            <input
                                                type="text"
                                                value={ name }
                                                onChange={ ( e ) => {
                                                    setIsNameValid( true )
                                                    setName( e.target.value )
                                                } }
                                                className={ `bg-gray-50 border ${ isNameValid ? 'border-gray-300' : 'border-red-500' } text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5` }
                                                placeholder="John Doe"
                                                required
                                            />


                                        </div>
                                        <div className="col-span-1">
                                            <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900">Your email*
                                                { !isEmailValid &&
                                                    <span className='ml-3 text-xs text-[#FF0000] font-light'>
                                                        *required
                                                    </span> }
                                            </label>
                                            <input
                                                type="text"
                                                value={ email }
                                                onChange={ ( e ) => {
                                                    setIsEmailValid( true )
                                                    setEmail( e.target.value )
                                                } }
                                                className={ `bg-gray-50 border ${ isEmailValid ? 'border-gray-300' : 'border-red-500' } text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5` }
                                                placeholder="name@company.com"
                                                required
                                            />
                                        </div>
                                        <div className="col-span-1">
                                            <label htmlFor="phoneNumber" className="block mb-2 text-sm font-medium text-gray-900">Your phone number*
                                                { !isPhoneNumberValid && (
                                                    <span className='ml-3 text-xs text-[#FF0000] font-light'>
                                                        { phoneError }
                                                    </span>
                                                ) }
                                            </label>
                                            <PhoneInput
                                                placeholder="Enter phone number"
                                                value={ phoneNumber }
                                                onChange={ ( e ) => {
                                                    setIsPhoneNumberValid( true )
                                                    setPhoneNumber( e )
                                                } }
                                                defaultCountry='IN'
                                                international
                                                countryCallingCodeEditable={ false }
                                                withCountryCallingCode={ true }
                                            />
                                        </div>
                                        <div className="col-span-1">
                                            <label htmlFor="grade" className="block mb-2 text-sm font-medium text-gray-900">
                                                Grade (optional)
                                            </label>
                                            <div className="relative">
                                                <select
                                                    value={ grade }
                                                    onChange={ ( e ) => setGrade( e.target.value ) }
                                                    className="appearance-none bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 pr-6"
                                                >
                                                    <option className="text-gray-700 bg-gray-50 hover:bg-gray-100" value="12th-pcb">
                                                        Completed 12th with PCB
                                                    </option>
                                                    <option className="text-gray-700 bg-gray-50 hover:bg-gray-100" value="12th-pcm">
                                                        Completed 12th with PCM
                                                    </option>
                                                    <option className="text-gray-700 bg-gray-50 hover:bg-gray-100" value="12th-commerce">
                                                        Completed 12th with Commerce
                                                    </option>
                                                    <option className="text-gray-700 bg-gray-50 hover:bg-gray-100" value="12th-humanities">
                                                        Completed 12th with Humanities
                                                    </option>
                                                    <option className="text-gray-700 bg-gray-50 hover:bg-gray-100" value="other">
                                                        Other
                                                    </option>
                                                </select>
                                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center p-2 text-gray-700">
                                                    <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                        <path d="M7 10l5 5 5-5H7z" />
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                    <button
                                        onClick={ handleSignup }
                                        className="w-full text-white font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                                        style={ {
                                            backgroundColor: clientConfig.primary_color,
                                        } }
                                    >
                                        Sign up
                                    </button>
                                </div>
                            </div>
                        ) }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SigninCouponCode;
