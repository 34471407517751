import React, { useState, useEffect } from 'react';
import { dynamicData } from '../../helpers/data';
import { Image, notification, Spin } from 'antd';
import useGeneratePdfReport from '../../api/useGeneratePdfReport';
import { useSelector } from 'react-redux';
import ThankYouPageOne from './ThankYouPageOne';
import { useMedia } from 'react-use';
import Lottie from 'lottie-react';
import thankYouanimation from '../../assets/Animation/thankyou_animation.json';
import thankyouImage from '../../assets/Animation/thankyoupageimage.png';

export const ThankYouSbit = () => {
    const student_name = localStorage.getItem( 'studentName' ) || "abc";
    const student_email = localStorage.getItem( 'user_email' ) || "abc@gmail.com";
    const [reportLink, setReportLink] = useState( null );
    const formConfigRedux = useSelector( state => state.formConfiguration );
    const [studentRollNumber, setStudentRollNumber] = useState( null );
    const [isDownloaded, setIsDownloaded] = useState( false );
    const isWide = useMedia( '(max-width: 500px)' );
    const clientConfig = useSelector( state => state.clientConfiguration );

    const formConfig = formConfigRedux?.form_config;
    const formid = formConfig?.form_id;
    const bookingLink = formConfig?.booking_url;

    const { data: pdfReport, isLoading: pdfReportGenerating, generatePdfReport } = useGeneratePdfReport();

    const handleBooking = () => {
        window.open( `${ bookingLink }/?name=${ student_name }&email=${ student_email }`, '_blank' );
    };

    useEffect( () => {
        if ( studentRollNumber ) {
            generatePdfReport( studentRollNumber, formid );
        }
    }, [studentRollNumber] );

    useEffect( () => {
        setStudentRollNumber( localStorage.getItem( 'student_roll_number' ) );
    }, [] );

    const handleDownloadReport = () => {
        setIsDownloaded( true )
    };

    useEffect( () => {
        if ( pdfReport && reportLink === null ) {
            setReportLink( pdfReport );
        }
    }, [pdfReport] );

    useEffect( () => {
        if ( reportLink && !pdfReportGenerating && isDownloaded ) {
            setIsDownloaded( false );
            try {
                const url = new URL(reportLink);
                const downloadLink = document.createElement( "a" );
                downloadLink.style.display = "none";
                downloadLink.href = reportLink;
        
                // Append the anchor element to the document body
                document.body.appendChild( downloadLink );
        
                // Trigger a click event on the anchor to start the download
                downloadLink.click();
        
                // Remove the anchor from the document
                document.body.removeChild( downloadLink );
            } catch (e) {
                console.error('Invalid PDF report URL:', reportLink);
            }
        }
    }, [reportLink, pdfReportGenerating, isDownloaded] );

    return (
        <>
            {
                pdfReportGenerating && isDownloaded &&
                <div div className="overlay">
                    <Spin size="large" />
                </div>
            }

            <ThankYouPageOne
                handleReport={ handleDownloadReport }
                handlementorshipbooking={ handleBooking }
            />
        </>
    );
};

//import React, { useState, useEffect } from 'react';
//import { dynamicData } from '../../helpers/data';
//import { notification, Spin } from 'antd';
//import useGeneratePdfReport from '../../api/useGeneratePdfReport';
//import { useSelector } from 'react-redux';
//import ThankYouPageOne from './ThankYouPageOne';

//export const ThankYouSbit = () => {

//    const student_name = localStorage.getItem( 'studentName' ) || "abc";
//    const student_email = localStorage.getItem( 'user_email' ) || "abc@gmail.com";
//    const [reportLink, setReportLink] = useState( null );
//    const formConfigRedux = useSelector( state => state.formConfiguration );
//    const [studentRollNumber, setStudentRollNumber] = useState( null );

//    const formConfig = formConfigRedux?.form_config;
//    const formid = formConfig?.form_id;

//    const { data: pdfReport, isLoading: pdfReportGenerating, generatePdfReport } = useGeneratePdfReport();

//    const handleBooking = () => {
//        window.open( `https://bookings.strideahead.io/team-stride/sbit-career-clarity-quiz/?name=${ student_name }&email=${ student_email }`, '_blank' );
//    }

//    useEffect( () => {
//        if ( studentRollNumber ) {
//            generatePdfReport( studentRollNumber, formid )
//        }
//    }, [studentRollNumber] )

//    useEffect( () => {
//        setStudentRollNumber( localStorage.getItem( 'student_roll_number' ) );
//    }, [] )


//    const handleDownloadReport = async () => {
//        if ( pdfReportGenerating ) {
//            notification.info( {
//                message: 'PDF Report is on it\'s way',
//            } );
//        }
//        if ( pdfReport ) {
//            try {
//                const url = new URL( pdfReport );
//                window.open( url, '_blank' );
//            } catch ( e ) {
//                console.error( 'Invalid PDF report URL:', pdfReport );
//            }
//        }
//    }

//    useEffect( () => {
//        if ( pdfReport && reportLink === null ) {
//            setReportLink( pdfReport );
//        }
//    }, [pdfReport] )

//    return (
//        <>
//            {
//                <ThankYouPageOne
//                    handleReport={ handleDownloadReport }
//                    handlementorshipbooking={ handleBooking }
//                />
//            }
//        </>
//    );
//};

