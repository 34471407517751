import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    ClockCircleFilled,
} from '@ant-design/icons';
import { setQuestionNumber } from '../../redux/student/studentSlice';

const PerQuestionTimer = ( { testTimeInSeconds, currentDynamicStep, timeCompleted, isWide } ) => {
    const [endTime, setEndTime] = useState( Date.now() + testTimeInSeconds * 1000 );
    const [timeLeft, setTimeLeft] = useState( testTimeInSeconds );
    const clientConfig = useSelector( state => state.clientConfiguration );
    const questionNumber = useSelector( state => state.currentQuestion.questionNumber );
    const dispatch = useDispatch();

    useEffect( () => {
        const interval = setInterval( () => {
            setEndTime( Date.now() + testTimeInSeconds * 1000 );
            setTimeLeft( testTimeInSeconds );
            const currentTime = Date.now();
            const newTimeLeft = Math.max( endTime - currentTime, 0 ) / 1000;
            setTimeLeft( newTimeLeft );

            if ( newTimeLeft === 0 ) {
                clearInterval( interval );
                dispatch( setQuestionNumber( questionNumber + 1 ) );
            }
        }, 0 );

        return () => clearInterval( interval );
    }, [currentDynamicStep, questionNumber] );

    const timerColor = timeLeft < 600 ? 'text-red-600' : 'text-green-600'; // Less than 10 minutes

    const formatTime = () => {
        const minutes = Math.floor( timeLeft / 60 );
        const seconds = Math.floor( timeLeft % 60 ); // Round down to the nearest whole number
        return `${ minutes }:${ seconds < 10 ? '0' : '' }${ seconds }`;
    };

    return (
        <div className="h-fit flex flex-row w-full items-center justify-center">
            {
                !isWide && <ClockCircleFilled style={ { fontSize: '2rem', color: clientConfig.primary_color } } />

            }
            <p className={ `${ isWide ? 'text-2xl' : 'text-xl ml-[1.5rem]' } text-black font-semibold w-full` }>Time Left : <span className={ `${ isWide ? 'text-2xl' : 'text-xl' } ${ timerColor }` }>{ formatTime() }</span></p>
        </div>

    );
};

export default PerQuestionTimer;
